body{font-family: "Open Sans", sans-serif !important;}
.overflow-x-hidden{overflow-x: hidden;}
a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: none !important;
}
:root { 
  --bs-blue: #3928d3;
  --bs-indigo: #4f46e5;
  --bs-purple: #5b2be0;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffcc43;
  --bs-green: #187958;
  --bs-teal: #22D3EE;
  --bs-cyan: #0dcaf0;
  --bs-black: #000000;
  --bs-white: #fff;
  --bs-gray: #4B5563;
  --bs-gray-dark: #1F2937;
  --bs-gray-100: #f9fafb;
  --bs-gray-200: #E5E7EB;
  --bs-gray-300: #D1D5DB;
  --bs-gray-400: #9CA3AF;
  --bs-gray-500: #6B7280;
  --bs-gray-600: #4B5563;
  --bs-gray-700: #374151;
  --bs-gray-800: #1F2937;
  --bs-gray-900: #111827;
  --bs-primary: #5b2be0;
  --bs-secondary: #1d253d;
  --bs-success: #187958;
  --bs-info: #22D3EE;
  --bs-warning: #ffcc43;
  --bs-danger: #dc3545;
  --bs-light: #f9fafb;
  --bs-dark: #111827;
  --bs-white: #fff;
  --bs-black: #000000;
  --bs-primary-rgb: 91, 43, 224;
  --bs-secondary-rgb: 29, 37, 61;
  --bs-success-rgb: 24, 121, 88;
  --bs-info-rgb: 34, 211, 238;
  --bs-warning-rgb: 255, 204, 67;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 249, 250, 251;
  --bs-dark-rgb: 17, 24, 39;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-primary-text: #24115a;
  --bs-secondary-text: #0c0f18;
  --bs-success-text: #0a3023;
  --bs-info-text: #0e545f;
  --bs-warning-text: #66521b;
  --bs-danger-text: #58151c;
  --bs-light-text: #374151;
  --bs-dark-text: #374151;
  --bs-primary-bg-subtle: #d7d4f6;
  --bs-secondary-bg-subtle: #f9fafb;
  --bs-success-bg-subtle: #d1e4de;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff5d9;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfdfd;
  --bs-dark-bg-subtle: #9CA3AF;
  --bs-primary-border-subtle: #b0a9ed;
  --bs-secondary-border-subtle: #E5E7EB;
  --bs-success-border-subtle: #a3c9bc;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffebb4;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #E5E7EB;
  --bs-dark-border-subtle: #6B7280;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 75, 85, 99;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.3;
  --bs-body-color: #4B5563;
  --bs-emphasis-color: #000000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(75, 85, 99, 0.75);
  --bs-secondary-color-rgb: 75, 85, 99;
  --bs-secondary-bg: #E5E7EB;
  --bs-secondary-bg-rgb: 229, 231, 235;
  --bs-tertiary-color: rgba(75, 85, 99, 0.5);
  --bs-tertiary-color-rgb: 75, 85, 99;
  --bs-tertiary-bg: #f9fafb;
  --bs-tertiary-bg-rgb: 249, 250, 251;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-heading-color: inherit;
  --bs-link-color: #5b2be0;
  --bs-link-color-rgb: 91, 43, 224;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #4922b3;
  --bs-link-hover-color-rgb: 73, 34, 179;
  --bs-link-hover-decoration: none;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff5d9;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #E5E7EB;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.275rem;
  --bs-border-radius-lg: 0.75rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 3.25rem;
  --bs-box-shadow: 0 0.3rem 1.8rem 0 rgba(55, 26, 134, 0.2);
  --bs-box-shadow-sm: 0 0.2rem 1.25rem 0 rgba(55, 26, 134, 0.1);
  --bs-box-shadow-lg: 0 0.4rem 2.2rem 0 rgba(55, 26, 134, 0.3);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(55, 26, 134, 0.075);
  --bs-emphasis-color: #000000;
  --bs-form-control-bg: var(--bs-body-bg);
  --bs-form-control-disabled-bg: var(--bs-secondary-bg);
  --bs-highlight-bg: #fff5d9;
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
  --bs-black-white-rgb: 0, 0, 0;
  --bs-black-white: #000000;
  --bs-light-dark-rgb: 249, 250, 251;
  --bs-light-dark: #f9fafb;
  --bs-white-black-rgb:#F7EAFF;
  --bs-white-black: #fff;
  --bs-border-light-dark: #E5E7EB;
  --bs-text-dark-light: #374151;
  --bs-gray-300: #D1D5DB;
}

.navbar {
    --bs-navbar-padding-x: 0;
    --bs-navbar-padding-y: 0.5rem;
    --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
    --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 0.8);
    --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
    --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
    --bs-navbar-brand-padding-y: 0.3125rem;
    --bs-navbar-brand-margin-end: 1rem;
    --bs-navbar-brand-font-size: 1.25rem;
    --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
    --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
    --bs-navbar-nav-link-padding-x: 0.2rem;
    --bs-navbar-toggler-padding-y: 0.25rem;
    --bs-navbar-toggler-padding-x: 0.75rem;
    --bs-navbar-toggler-font-size: 1.25rem;
    --bs-navbar-toggler-icon-bg: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%2833, 37, 41, 0.75%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e);
    --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
    --bs-navbar-toggler-border-radius: var(--bs-border-radius);
    --bs-navbar-toggler-focus-width: 0.25rem;
    --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
    position: relative;  display: flex;  flex-wrap: wrap;  align-items: center;  justify-content: space-between; padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.sticky-top {z-index: 1000 !important;}
.sticky-top {top: -1px !important;z-index: 1020;}

.h-calc{height: calc(100dvh - 130px);}
.h-calc img{width: 100%; object-fit: cover; background-position: top center;}
.absolute-content{position: absolute;max-width:auto; bottom:10%;background-color: #00000042; padding: 30px; border-radius: 15px;}
.slider-heading{font-size:48px; line-height:64px; font-weight: 700;}
.liner-text{background:linear-gradient(to right, rgb(247 0 0), rgb(255 255 255));-webkit-background-clip: text; -webkit-text-fill-color: transparent;}
.liner-text2{background:linear-gradient(to right, rgb(247 0 0), rgb(0, 0, 0));-webkit-background-clip: text; -webkit-text-fill-color: transparent;}
.slider-small-heading{font-size: 24px; line-height: 28px;}
.logo-top img{height: 70px;}
.social-icons{gap:20px}
.social-icons a{color: #333;}
.social-icons a:hover{color: #191911;}
.h-450{height: 450px;}
.top-head{font-size:28px; line-height: 32px; font-weight: 700; color: #000;}
.top-small-head{font-size:15px; line-height:18px; color: #000;}
.top-section .sticky-top {top:-1px;}

.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {content: 'prev';background: #ddd;padding:10px 12px;border-radius: 4px; font-size: 24px !important; color: #000;}
.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {content: 'next';background: #ddd;padding: 10px 12px; border-radius: 4px;font-size: 24px !important; color: #000;}
.swiper-pagination-bullet {background-color: #FFF !important;opacity: 1 !important;}
.swiper-pagination-bullet-active {opacity: var(--swiper-pagination-bullet-opacity, 1); background:red !important;}
.our-topers{font-size: 40px; line-height: 48px; font-weight: 700; text-align: center;text-transform: uppercase;}
.testmonial .swiper-pagination-bullet {background-color: #000000 !important;opacity: 1 !important;}
.testmonial .swiper-pagination-bullet-active {opacity: var(--swiper-pagination-bullet-opacity, 1); background:red !important;}
.bg-dark-grey{background-color: #FAFAFA;}
.small-slider-section .top-rank-section{display: block; padding-bottom: 20px;}
.small-slider-section .thumb-img{border-radius: 5px;}
.small-slider-section .thumb-img img{height:120px; width: 120px; border-radius: 5px;object-fit: cover; background-size: 120px;}
.small-slider-section h2{font-size:20px; line-height:32px; font-weight: 600;}
.small-slider-section h3{font-size:14px; ;line-height:18px; color: #333;}
.small-slider-section p{margin-bottom: 0; font-size: 14px;}
.small-slider-section .swiper-pagination {position: relative;}
span.swiper-pagination-bullet {height: 16px;width: 16px;}

.small-slider-section{padding:30px; background-color: white; border-radius: 8px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);}
.liner-button.btn.btn-primary{background:linear-gradient(to right, rgb(247 0 0), rgb(6 3 3)); font-size: 20px; line-height:32px;padding: 4px 12px; color: #FFF;max-width: 240px;border-color: transparent;border-width: 0px;}

button.liner-button.btn.btn-primary{background:linear-gradient(to right, rgb(247 0 0), rgb(6 3 3)); font-size: 20px; line-height:42px;padding: 4px 32px; color: #FFF;max-width: 240px;border-color: transparent;border-width: 0px;}
.font-weight-bold{font-weight: 700;}
.mh-300{min-height: 300px;}
.mh-200{min-height:230px;}
.f-54{font-size:54px; line-height:62px;}
.f-40{font-size:40px; line-height:48px;}
.f-32{font-size:32px; line-height: 38px;}
.f-28{font-size:28px; line-height: 34px;}
.f-24{font-size: 24px; line-height: 28px;}
.f-20{font-size: 20px; line-height: 24px;}
.f-18{font-size:18px; line-height: 22px;}
.f-16{font-size: 16px; line-height:20px;}
.social-content iframe {border: 0;min-height: 400px;width: 100%;}
.list-sec ul{padding-left:0px;}
.list-sec ul li{list-style: none; padding-left:30px;position: relative;padding-bottom:10px;}
.list-sec ul li::before{content: '';background: url('../public/assets/img/arrow-right.png');background-size:100%;background-position:top;position:absolute; left:0; top:4px;width:16px;height:16px;}
.pr-100{padding-right: 100px;}
.vertical-marquee-container {width: 100%;height: 100px;overflow: hidden;position: relative;border: 1px solid #f1f1f1;padding:15px;border-radius: 5px;}
.vertical-marquee {display: inline-block;animation: vertical-marquee 12s linear infinite;white-space: normal;}
.paused .vertical-marquee {animation-play-state: paused;}

@keyframes vertical-marquee {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(-100%);
  }
}

.bg-body-tertiary .dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color:#ddd !important;
}
.toper-bg{background-repeat: no-repeat;width: 100%; object-fit: cover;background-size: cover;}
.mng img{object-fit: cover;aspect-ratio: 1.05;width: 100%;height: 100%;}

.infra-head{font-size: 20px;line-height: 24px; text-align: center; font-weight: 700;background-color: rgb(0 0 0 / 6%) !important;}
.hover-box-up {transition: all 0.3s ease-in-out;}
.hover-box-up:hover {-webkit-transform: translateY(-10px);transform: translateY(-10px);}
.gallery-section .gallery-thumb{max-height:322px; max-width:322px;}
.gallery-section img{max-height:100%;max-width:100%; object-fit: cover;}

.reviews-one, .reviews-two {min-height: 220px; border-radius:15px; position: relative;background-color:#F7EAFF;box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);padding:15px;}
blockquote {margin: 0 0 1rem;}
.ratings {padding: 0;margin: 0;display: -ms-flexbox;display: flex;}
.ratings li.active {color: #ffcc43;}
.ratings li {padding: 0;margin: 0 10px 0 0;list-style: none;}
.ratings li svg {stroke: #371a86;}
.ratings li.active svg .rating-svg {stroke: #ffcc43;}
.reviews-one-thumb, .reviews-two-thumb {width: 3.75rem;height: 3.75rem;}
.rounded-circle {border-radius: 50% !important;}
.almuni-section .card{max-width:310px; max-height: 400px;}
.almuni-section .card img{height: 100%; width: 100%; object-fit: cover;aspect-ratio: 1.3;}
.almuni-section im{width:100%;height: 100%;}

.fals-section{background-color: #FFF; border-radius: 20px;padding: 28px;height: 165px;box-shadow:0px 1px 9px 0px rgba(0, 0, 0, 0.1);}
.small-slider-section .swiper-wrapper {display: flex;animation: marquee 15s linear infinite;}

@keyframes marquee {
0% {
  transform: translateX(0);
}
100% {
  transform: translateX(-100%);
}
}

.form-control {border-color: var(--bs-border-light-dark);}
.form-control {display: block;width: 100%;padding: 0.75rem 1.875rem;font-size: 1rem;font-weight: 400;line-height: 1.3;color: #374151;-webkit-appearance: none;-moz-appearance: none;appearance: none;background-color: var(--bs-form-control-bg);background-clip: padding-box;border: var(--bs-border-width) solid #E5E7EB;border-radius: 0.375rem;transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;}

.contact-area > div {margin-bottom: 1.5rem;}
.text-primary {--bs-text-opacity: 1;color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;}
.form-content{min-height:74vh;}
.contact-area{min-height:74vh;}
.responsive-maps iframe{height:400px; width:100%;margin-top:50px;}
.responsive-maps2 iframe{height:150px; width:100%;margin-top:0px;}
.bg-body-tertiary li.lavel-1 .nav-item > a .nav-link::after {position: absolute;z-index: -1;right: 0;bottom: 0;left: auto;width: 0;height: 1px;content: "";-webkit-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;-o-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;background: currentColor;}

@media (min-width: 992px) {
  .nav-link {font-size: var(--bs-nav-link-font-size); font-weight: var(--bs-nav-link-font-weight); color: var(--bs-nav-link-color); text-transform: uppercase;}
  .navbar-expand-lg .navbar-nav {justify-content: space-between; width: 100%;}
  .dropdown .dropdown-toggle::after {display: inline-block;margin-left: .255em; vertical-align: 0.155em;}
  .sticky-top {background-color: #eee !important;border-bottom: 1px solid #c8c8c8;}
  .bg-body-tertiary a:hover {color: var(--bs-red);text-decoration: none;}
  .bg-body-tertiary .navbar-nav .nav-link.active, .navbar-nav .nav-link.show {color: rgb(255 0 0) !important;}
  .navbar-expand-lg .navbar-nav .dropdown-menu {top: 36px;}
}

.nav-link a{color: #000; text-decoration: none;}
@media (max-width: 992px) {
  .bg-body-tertiary {background-color: rgb(255 255 255) !important;border-bottom: 1px solid #ddd;}
  .top-head {font-size: 22px; line-height: 28px;}
  .navbar-collapse {position: absolute;top: 94px;background: #FFF;width: 100%;left: 0;padding: 12px 20px;border-top: 1px solid #ddd;}
  .social-icons{gap: .4rem !important;}
  .social-icons svg{height: 20px;}
  .absolute-content{position: absolute;max-width:100%; bottom:0;top:40px;max-height:200px;padding: 10px 6px;}
  .slider-heading{font-size:24px; line-height:34px;}
  .hero-section .swiper-slide {height:250px !important;}
  .hero-section .swiper-button-prev, .swiper-button-next{display: none !important;}
  .swiper-pagination{display:none;}
  .nav-link{border-bottom: 1px solid #ddd;}
  .top-section.pt-2 {padding-top: 0rem !important;}
  .pr-100{padding-right: 0px;}
  .h-calc{height:250px;}
  .h-calc img{height:250px;}
  button.liner-button.btn.btn-primary {font-size: 16px; line-height: 30px;padding: 4px 12px;}
  .liner-button.btn.btn-primary {font-size: 16px; line-height: 30px;padding: 4px 12px;}
  .pt-30{padding-top: 30px;}
  .our-topers {font-size: 28px;line-height: 34px;}
  h2.our-topers.liner-text2.mt-5 {margin-top:1rem !important;}
  .pt-4.mt-5.gallery-section{margin-top:1rem !important;}  
}


.bg-dark {--bs-bg-opacity: 1;background-color: #1d253d !important;}

.footer-dark {color: var(--bs-gray-300);}
.widget-content {padding-bottom: 0.5rem;}
.footer-dark .h1, .footer-dark .h2, .footer-dark .h3, .footer-dark .h4, .footer-dark .h5, .footer-dark .h6, .footer-dark .h7, .footer-dark h1, .footer-dark h2, .footer-dark h3, .footer-dark h4, .footer-dark h5, .footer-dark h6 {color: var(--bs-white);}
.list-unstyled {padding-left: 0;list-style: none;}

.footer-dark .border-top {border-top: var(--bs-border-width) var(--bs-border-style) #393939 !important;}
.footer-dark .before-arrow{position: relative;padding-left: 20px;}
.footer-dark .before-arrow li::before {content:''; background-image:url('data:image/svg+xml, url(data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"%3e%3cpolyline points="112 184 256 328 400 184" fill="none" stroke="%23D1D5DB" stroke-linecap="round" stroke-linejoin="round" stroke-width="48px"/%3e%3c/svg%3e)');-webkit-transform: rotate(-90deg);transform: rotate(-90deg);  color: #FFF;height: 16px;width: 16px;position: absolute;left: 0;background-repeat: no-repeat;margin-top: 3px;}
section p, .section p {line-height: 1.6;}
address {margin-bottom: 1rem;font-style: normal;line-height: inherit;}
.footer-dark a{color: #FFF; text-decoration: none;}
.footer-dark a:hover{color: #d8d8d8; text-decoration: none;}

.fb-color{color: #1877F2;}
.tw-color{color: #000;}
.insta-color{color: #E1306C;}
.youtube-color{color: #FF0000;}

.service-2 {display: block;width: 100%;height: 240px;overflow: hidden;position: relative;margin: 0px 10px 20px 0px;border: 1px solid #ddd;}

.hover-service img {width: 100%;}
.service-2 img {width: 100%;margin: 0%;transition: all 0.5s ease-in 0s;float: left;}
.hover-service .service-brief {transition: transform .7s, opacity .5s .2s;opacity: 0;transform: scale(.7);position: absolute;width: 100%;height: 100%;}

.hover-service:hover .service-brief {transition: transform .7s, opacity .5s;opacity: 1;transform: scale(1);background-color: rgba(25, 47, 89, 0.7);}
.service-2 .service-brief {transition: transform .7s, opacity .5s .2s;opacity: 0;transform: scale(.7);position: absolute;width: 100%;height: 100%;}
.elementor-flip-box-layer-overlay {display: flex;width: 100%;height: 100%;flex-direction: column;align-items: stretch;text-align: center;color: #fff;padding: 16px 10px 0px;}
.service-2 h4 {color: #fff;}
.elementor-flip-box-layer-overlay h4 {font-size: 21px;line-height: 22px;margin-bottom: 3px;}
.service-2 p {color: #fff;min-height: 80px;}
.elementor-flip-box-layer-overlay p {font-size: 14px;line-height: 21px;}
.btn-inline-button {text-align: center;width: 100%;float: left;}
.elementor-button {display: inline-block;line-height: 1;font-size: 15px;padding: 12px 24px;border-radius: 3px;color: #ffffff;text-align: center;transition: all .3s;width: auto;text-decoration: underline !important;}
.elementor-flip-box-layer-overlay-1 {position: absolute;text-align: center;bottom: 0;background: #000000a1;padding: 10px 12px;left: 0;right: 0;}
.elementor-flip-box-layer-overlay-1 h4 {margin-bottom: 0;font-size: 20px;}

.service-item {padding-bottom: 30px;}
.page-title-section2 {padding:70px 0 50px;text-align: center;}
.page-title-section2 h1 {font-size: 40px;font-weight: 600;letter-spacing: 1px;line-height: 1;color: #fff;margin-bottom: 0;}
.page-title-section2 ul {margin-bottom: 0;margin-top: 15px;text-align: center;}
.page-title-section2 ul li {display: inline-block;}
.page-title-section2 ul li a {color: #fff;font-size: 16px;font-weight: 500;text-decoration: none;}
.page-title-section2 ul li:after {content: '\f105';font-weight: 700;vertical-align: middle;color: #fff;font-family: Font Awesome\ 5 Free;padding: 0 5px 0 10px;}

.page-title-section2 ul li:last-child a {color: #FF4B36;}
.cover-background {position: relative !important;background-size: cover !important;overflow: hidden !important;background-position: center !important;background-repeat: no-repeat !important;}

.bg-container {/* background: url(assets/img/bg-pattern.png) no-repeat; */position: absolute;left:0;top:0;width: 100%;background-size: auto auto;height: 100%;}
section.about-section {padding:80px 0;overflow: hidden;background: #fff;position: relative;}
.about-section img {max-width: 100%;height: auto;vertical-align: top;}
.section-heading.left {text-align: left;}
.section-heading.half {margin-bottom: 30px;position: relative;}
.section-heading h1:after, .section-heading h2:after, .section-heading h3:after, .section-heading h4:after, .section-heading h5:after, .section-heading h6:after {content: '';display: block;width: 100px;height: 2px;background: #FF4B36;margin: 0 auto;position: absolute;left: 0;bottom:-10px;}
.list-style-16 {list-style: none;margin: 0;padding: 0;}
.list-style-16 li {line-height: 24px;margin-bottom: 10px;position: relative;padding-left: 30px;}
.list-style-16 li:after {color: #FF4B36;}
.list-style-16 li:after {content: '';background-image: url(data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"%3e%3cpolyline points="112 184 256 328 400 184" fill="none" stroke="%23D1D5DB" stroke-linecap="round" stroke-linejoin="round" stroke-width="48px"/%3e%3c/svg%3e);-webkit-transform: rotate(-90deg);transform: rotate(-90deg);  color: #FFF;height: 16px;width: 16px;position: absolute;left:4px;background-repeat: no-repeat;left:4px;top: 4px;z-index: 1;color: #282b2d;}
.g-2, .gy-2 {--bs-gutter-y: 0.5rem;}
.border-radius-5 {border-radius: 5px;}
.parallax {position: relative;background-size: cover;overflow: hidden;background-attachment: fixed;}
[data-overlay-dark="0"]:before, [data-overlay-light="0"]:before {opacity: 0;}
[data-overlay-dark]:before, [data-overlay-light]:before {content: '';position: absolute;width: 100%;height: 100%;top: 0;left: 0;z-index: 1;}
.about-text {padding-bottom: 90px;padding-top: 90px;position: relative;display: block;overflow: hidden;padding: 90px 40px;z-index: 1;height: 100%;}
.about-overlay:before {position: absolute;top: 0;left: 0;width: 100%;height: 100%;background: rgba(0, 0, 0, 0.85);content: "";z-index: -1;}
.w-xl-85 {width: 85%;}
.about-text .inner-content h2 {font-size: 24px;font-weight: 400;line-height: 36px;margin: 0 0 14px;color: #fff;}
.about-text .inner-content h2 span {font-weight: 700;}
.about-text .inner-content p {color: #fff;}
.about-text {display: block;padding-bottom: 90px;padding-top: 90px;position: relative;display: block;overflow: hidden;padding: 90px 40px;z-index: 1;height: 100%;}

.liner-text3 {
  background: linear-gradient(to right, rgb(247 0 0), rgb(191 191 191));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.bg-secondary-2 {
  background-color: #1f2732 !important;
}
.line-shape {
  position: absolute;
  left: 0;
  top:50px;
  z-index: -1;
  transform: rotate(-3deg);
}
/* **************animation**************** */
.scrolling-text {
  width: 100%;
  overflow: hidden
}

.scrolling-marquee {
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  animation: campoutscrollingmarqueeReverse 6s linear infinite
}

.scrolling-left-text .scrolling-marquee {
  animation: campoutscrollingmarquee 6s linear infinite
}

.scrolling-marquee h5 {
  margin: 0px;
  padding: 0px;
  display: inline-block
}

@keyframes campoutscrollingmarquee {
  0% {
      transform: translate3d(0, 0, 0)
  }

  100% {
      transform: translate3d(-50%, 0, 0)
  }
}

@keyframes campoutscrollingmarqueeReverse {
  0% {
      transform: translate3d(-50%, 0, 0)
  }

  100% {
      transform: translate3d(0%, 0, 0)
  }
}

.scrolling-left-text .scrolling-marquee {
  animation-duration:10s
}

.scrolling-left-text .scrolling-marquee h5 {
  color: #fff
}

.scrolling-marquee {
  animation-duration: 10s
}

.scrolling-text h5 {
  color: #fff;

font-size: 20px;line-height: 30px;
}


.absolute-winner-01{position: absolute; left:0;top:0;z-index: -1;}
.absolute-winner-02{position: absolute; right:0;top:0;z-index: -1;}
.absolute-winner-01 img, .absolute-winner-02 img{height: 280px;opacity: .1;}
.relative-ribbon-03 img{height:88px;}




.sidebar .widget {
  margin-bottom: 30px;
  padding: 30px;
  box-shadow: 0px 5px 24.25px 0.75px rgba(0,0,0,0.1);
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px
}

.sidebar .widget:last-child {
  margin-bottom: 0
}

.sidebar .widget-title {
  margin-bottom: 1.5rem
}

.sidebar .widget-title h3 {
  font-size: 1.3rem;
  position: relative;
  margin-bottom: 0;
  padding-left: 25px
}

.sidebar .widget-title h3:after {
  position: absolute;
  content: "";
  display: inline-block;
  left: 10px;
  top: 2px;
  width: 3px;
  background: #ff7029;
  height: 20px
}

.sidebar .widget-title h3:before {
  position: absolute;
  content: "";
  display: inline-block;
  left: 0;
  top: 2px;
  width: 3px;
  background: #ff7029;
  height: 20px
}


.course-list {
  margin: 0;
  padding: 0;
  list-style: none
}

.course-list li {
  position: relative;
  padding-left: 0;
  font-size: 16px;
  font-weight: 700;
  border-bottom: 1px dotted #dddddd;
  padding-bottom: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between
}

.course-list li span:first-child {
  padding-right: 20px
}

.course-list li i {
  font-size: 14px;
  color: #ff7029
}

.course-list li:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none
}

.category-list {
  margin: 0;
  padding: 0;
  list-style: none
}

.category-list li {
  font-size: 16px;
  font-weight: 700;
  border-bottom: 1px dotted #dddddd;
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 10px;
  position: relative;
  padding-left: 20px
}

.category-list li::before {content: ''; background-image: url(data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"%3e%3cpolyline points="112 184 256 328 400 184" fill="none" stroke="%23D1D5DB" stroke-linecap="round" stroke-linejoin="round" stroke-width="48px"/%3e%3c/svg%3e);-webkit-transform: rotate(-90deg);transform: rotate(-90deg);  color: #FFF;height: 16px;width: 16px;position: absolute;left:0px;background-repeat: no-repeat;top: 4px;z-index: 1;color: #282b2d;}


.category-list li a {
  color: #000000; font-weight: 600;text-decoration: none;
}

.category-list li a:hover {
  color: #ff7029
}

.category-list li span {
  position: absolute;
  right: 0;
  top: 1px;
  width: 24px;
  height: 24px;
  line-height: 26px;
  text-align: center;
  border-radius: 5px;
  display: block;
  font-size: 13px;
  font-weight: 700;
  color: #fff;
  background-color: #ff7029
}

.category-list li a i {
  color: #ff7029;
  font-size: 15px;
  display: inline-block
}

.sidebar .widget .course-tags {
  margin-bottom: 0;
  padding: 0
}

.sidebar .widget .course-tags li {
  display: inline-block
}

.sidebar .widget .course-tags li a {
  font-size: 14px;
  font-weight: 700;
  border-radius: 4px;
  text-transform: capitalize;
  padding: 8px 12px;
  margin: 5px 5px 5px 0;
  display: inline-block;
  border: 1px solid #ededed
}

.sidebar .widget .course-tags li a:hover {
  background: #ff7029;
  color: #ffffff;
  border: 1px solid #ff7029
}

.sidebar .widget .social-icons li {
  display: inline-block;
  margin-right: 5px;
  text-align: center
}

.sidebar .widget .social-icons li:last-child {
  margin-right: 0
}

.sidebar .widget .social-icons li a {
  height: 35px;
  width: 35px;
  line-height: 36px;
  font-size: 14px;
  border-radius: 5px;
  display: inline-block;
  background: #2fbfa7;
  color: #ffffff
}

.sidebar .widget .social-icons li a:hover {
  color: #ffffff;
  background-color: #ff7029
}

@media screen and (max-width: 1199px) {
  .sidebar .widget {
      padding:25px
  }
}

@media screen and (max-width: 991px) {
  .course-list li,.category-list li {
      font-size:15px
  }
}

@media screen and (max-width: 767px) {
  .courses-sidebar-title {
      padding:12px 0
  }

  .sidebar .widget {
      padding: 20px
  }

  .course-list li {
      font-size: 14px
  }

  .category-list li {
      font-size: 14px
  }

  .category-list li span {
      width: 22px;
      height: 22px;
      line-height: 22px;
      font-size: 12px
  }

  .sidebar .widget .course-tags li a {
      font-size: 13px;
      padding: 6px 10px
  }

  .sidebar .widget .social-icons li a {
      height: 32px;
      width: 32px;
      line-height: 34px;
      font-size: 13px
  }
}


.rounded {
  border-radius: .5rem !important;
}

.h-400px {
  height: 400px !important;
}

img {
  max-width: 100%;
  height: auto;
}

.text-orange {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-orange-rgb), var(--bs-text-opacity)) !important;
}

.myjustify {
  text-align: justify;
}
.my-text-center {
  text-align: center;
}
.my-top-mar {
  margin-top: 15px;
}
.my-bg {
  background-color: none;
}

.table-responsive td:first-child, th:first-child{text-align: center;}
.table-responsive td:last-child, th:last-child{text-align: center;}
